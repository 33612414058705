<template>
  <tbody class="file-row">
    <order-copy :copy="value" :narrow="true" />
    <tr>
      <template>
        <td :colspan="el.width < 449 ? 5:7" class="drop-table-cell">
          <draggable
            class="copy-dropzone"
            v-model="fileList"
            :class="stateClasses"
            group='people'
            @change="drop"
          >
            <file-item
              v-for="item in fileList"
              :key="item.name"
              :file="item"
              :url="url"
              :type="item.type"
              @sync="sync"
              tag="div"
            />
          </draggable>
        </td>
        <div class="dragcopy_buttons" v-if="!isTwoSided">
          <button style="max-width: 5rem; min-width: 5rem;" id="blancBtn" class="button is-secondary" @click="quickedit(value.id)" v-if="quickEditUsers.includes(this.$store.state.user.id)  && fileList.length > 0 && fileList[0].type == 'linked' ">
            <svg-icon style="width: 1rem;" icon="pencil" class="svg"/>
          </button>
          <button id="blancBtn" class="button is-primary" @click="createBlancFile">{{$t('files.blanc_files')}}</button>
        </div>
      </template>
    </tr>
  </tbody>
</template>
<script>
import draggable from "vuedraggable";
import FileItem from "./FileItem";
import OrderCopy from "../Order/Views/OrderCopy";
import responsiveMixin from "../../utilities/responsiveMixin";
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    url:{
      type: String
    }
  },
  data() {
    return {
      fileList: this.files,
      quickEditUsers: [728, 1051,1083,1107,1165,486,1200,1234,1028,1073]
      // intern,dickson,luuk,paul,paulv,Thijs,Michel,Koen,Bob,Jeroen
    };
  },
  mixins: [responsiveMixin],
  methods: {
    createBlancFile(){
      this.$http.post(`createBlancpdf/${this.value.id}`)
      .then(result =>{
        this.$emit("sync");
      })
      .catch(error => {
        console.log(error);
      })
    },
    quickedit(id){
      this.$emit("quickedit", id);
    },
    drop(event) {
      const type = Object.keys(event)[0];
      const payload = event[type].element;
      if (this.hasOwnProperty(type)) {
        this[type](payload);
      }
    },
    sync() {
      this.$emit("sync");
    },
    removed(file) {
      this.$emit("remove", file);
    },
    moved() {
      this.fileList = this.fileList.map((file, index) => {
        file.position = index === 0 ? "front" : "back";
        return file;
      });
      this.$emit("update", this.fileList);
    },
    added(changed) {
      // Need to check for two sided order lines.
      // Filter old list.
      const file = this.fileList.find(file => file.name === changed.name);
      this.$set(file, "type", "suggestion");
      this.$set(file, "lineId", this.value.id);

      const indexOf = this.fileList.indexOf(file);
      if (!this.isTwoSided && this.fileList.length > 0) {
        let remove = this.fileList.splice(indexOf === 0 ? 1 : 0, 1);
        this.removed(remove);
      }
      if (this.isTwoSided && this.fileList.length > 2) {
        if (indexOf === this.fileList.length - 1) {
          let remove = this.fileList.splice(1, 1);
          this.removed(remove);
        } else {
          let remove = [this.fileList.pop()];
          this.removed(remove);
        }
      }
      this.moved();
    }
  },
  computed: {
    stateClasses() {
      return {
        "is-empty":
          (!this.isTwoSided && this.fileList.length === 0) ||
          (this.isTwoSided && this.fileList.length < 2),
        "is-two-sided": this.isTwoSided,
        "is-one-sided": !this.isTwoSided
      };
    },
    isTwoSided() {
      return (this.value.sides === 2 && this.value.abaa === 1) || this.value.double_layer;
    },
    orderId() {
      return this.$route.path;
    }
  },
  watch: {
    files(val) {
      this.fileList = val;
    }
  },
  components: {
    draggable,
    FileItem,
    OrderCopy
  }
};
</script>
<style lang="scss">
@import "~vars";

.drop-table-cell {
  background-color: $grey-light;
  padding: 0 !important;
  .copy-dropzone {
  text-align: center;
  }
}
.dragcopy_buttons{
  display: flex;
}
#blancBtn{
  height: 3rem;
  width: 100%;
}

tbody.file-row {
  border-top: 1px solid $grey;

  td {
    border-bottom: none;
  }
}

.copy-dropzone {
  position: relative;
  &.is-empty {
    background-color: $grey-light;
    &::before {
      display: block;
      color: $grey;
      text-shadow: 1px 1px #fff;
      padding-top: 1rem;
      padding-left: 1rem;
    }

    &.is-one-sided::before {
      content: "Koppel uw bestand hier";
      position: absolute;
      top: 0;
      left: 0;
    }

    &.is-two-sided::before {
      content: "Koppel EERST uw bestand VOORZIJDE";
    }

    &.is-two-sided::after {
      content: "Koppel VERVOLGENS uw bestand ACHTERZIJDE";
      display: block;
      color: $grey;
      text-shadow: 1px 1px #fff;
      padding-top: 1rem;
      padding-left: 1rem;
    }
  }
}
</style>
