<template>
  <table class="invoice-lines table is-narrow is-fullwidth is-striped">
    <thead>
      <tr>
        <th v-for="header in tableHeaders" :key="header">{{ $t('financial.' + header) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="line in invoiceLines"
        :key="line.werkvoorbereidingid + line.omschrijving"
        class="invoie-line-price"
      >
        <td>{{ line.omschrijving }}</td>
        <td v-if="!product.includes('PlusFrame')"><p>{{ line.aantal.toFixed(2) }}</p></td>
        <td v-else></td>
        <td>
          <p>{{ line.tarief | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}</p>
        </td>
        <td v-if="discount">
          <p>
            <template v-if="line.korting > 0">
              {{ line.korting }}%
            </template>
          </p>
        </td>

        <td>
          <p>{{ line.bedrag | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}</p>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th :colspan="discount ? 4 : 3">{{ $t('financial.total_ex') }}</th>
        <th v-if="totalPrice">
          {{ totalPrice | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}
        </th>
        <th v-else></th>
      </tr>
    </tfoot>
  </table>
</template>
<script>
import currency from "../../../utilities/filters/currency";
export default {
  props: {
    invoiceLines: Array,
    product: String
  },
  filters: {
    currency
  },
  computed: {
    tableHeaders() {
      return this.invoiceLines.reduce((headers, invoiceLine) => {
        let keys = Object.keys(invoiceLine);
        if (keys.includes('werkvoorbereidingid')) {
          keys.shift();
        }
        if (headers.length < keys.length) {
          headers = keys;
        }
        if (!this.discount) {
          return headers.filter(header => header !== 'korting')
        }
        return headers;
      }, []);
    },
    discount() {
      return !!this.invoiceLines.find(line => line.korting > 0);
    },
    totalPrice() {
      return this.invoiceLines.reduce((accumulator, invoiceLine) => {
        return (accumulator += invoiceLine.bedrag);
      }, 0);
    }
  }
};
</script>

<style lang="scss">
@import "~vars";

.invoie-line-price td {
  p{
  width: 50px;
  text-align: right;
  } 
}

</style>
