<template>
  <div class="image-director" v-if="!loading">
    <div class="image-director__head">
      <activity-view :activities="activities" />
    </div>
    <hr>
    <div class="image-director__body">
      <div class="image-viewer__copies">
        <template v-if="files.length > 0">
          <image-copy
            v-for="copy in copies"
            :key="copy.id"
            :copy="copy"
            :files="getFilesForRow(copy.id)"
            :url="url"
            :approvalNeeded="approvalNeeded"
          />
        </template>
        <extra-files v-if="extra.length > 0" :extra="extra" :url="url" />
        <approval-form v-if="approvalNeeded" @close="$emit('close')"/>
      </div>
    </div>
    <!-- div class="image-director__body">
      <div class="copies__files">
        <order-copies :copies="copies" :narrow="true">
          <template v-slot="{ copies }">
            <image-copy
              v-for="copy in copies"
              :copy="copy"
              :key="copy.id"
              :files="getFilesForRow(copy.id)"
              :url="url"
            />
          </template>
        </order-copies>
      </div>
      <div class="todo__files">
        <strong class="todo__label">{{ $t('files.bonus') }}</strong>
        <div v-for="file in extra" :key="file.name">
          <image-file :url="url" :image="file" />
        </div>
      </div>
    </div> -->
  </div>
  <span v-else class="button is-borderless is-loading"></span>
</template>
<script>
import ActivityView from "./ImageViewer/ActivityView";
import ImageCopy from "./ImageViewer/ImageCopy";
import ApprovalForm from "./ImageViewer/ApprovalForm";
import ExtraFiles from "./ImageViewer/ExtraFiles";
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    copies: {
      type: Array,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    approvalNeeded: Boolean
  },
  data() {
    return {
      loading: true,
      files: [],
      extra: []
    };
  },
  methods: {
    getFilesForRow(copyId) {
      return this.files.filter(file => file.lineId === copyId);
    }
  },
  async created() {
    const { data } = await this.$http.get(this.url);
    for (let key in data) {
      this[key] = data[key];
    }
    this.loading = false;
  },
  components: {
    ActivityView,
    ImageCopy,
    ApprovalForm,
    ExtraFiles
  }
};
</script>
<style lang="scss">
  .image-viewer__copies {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    width: 100%;

    .approval_form {
      flex: 1 0 100%;
    }
  }
</style>
