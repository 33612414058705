<template>
  <table class="table is-narrow is-striped is-fullwidth is-hoverable">
    <thead>
      <tr v-if="!narrow">
        <th>Product</th>
        <th>{{ $t('copies.dimensions') }}</th>
        <th class="is-narrow">{{ $t('copies.originals') }}</th>
        <th class="is-narrow">{{ $t('copies.quantity') }}</th>
        <th class="is-narrow">{{ $t('copies.side_printed') }}</th>
        <th>{{ $t('copies.file_remark') }}</th>
        <th v-if="hasPrice">{{ $t('copies.unit_price') }}</th>
      </tr>
      <tr v-else>
        <th>Product</th>
        <th>{{ $t('copies_short.dimensions') }}</th>
        <th class="is-narrow">{{ $t('copies_short.originals') }}</th>
        <th class="is-narrow">{{ $t('copies_short.quantity') }}</th>
        <th class="is-narrow">{{ $t('copies_short.side_printed') }}</th>
        <th>{{ $t('copies_short.file_remark') }}</th>
        <th v-if="hasPrice">{{ $t('copies_short.unit_price') }}</th>
      </tr>
    </thead>
    <slot :copies="filteredCopies">
      <tbody>
        <order-copy
          v-for="copy in filteredCopies"
          :key="copy.id"
          :copy="copy"
          :narrow="narrow"
        ></order-copy>
      </tbody>
    </slot>
  </table>
</template>
<script>
import OrderCopy from "./OrderCopy";
export default {
  props: {
    copies: {
      type: Array,
      required: true
    },
    narrow: Boolean
  },
  computed: {
    filteredCopies() {
      let copies = JSON.parse(JSON.stringify(this.copies));
      if (this.narrow) {
        copies = copies.map(copy => {
          copy.file_front = "";
          copy.file_back = "";
          return copy;
        });
      }
      return copies;
    },
    hasPrice() {
      return this.filteredCopies.reduce((acc, copy) => {
        if (copy.price === false) {
          acc = false;
        }
        return acc;
      }, true);
    }
  },
  components: {
    OrderCopy
  }
};
</script>
<style lang="scss">
  .invoice-lines {
    max-width: 800px;
  }
</style>
