<template>
  <component :is="tag" :title="file.name" class="file-item" :class="type">
    <div class="file-item__content">
      <div class="file-item-meta">
        <span
          class="file-item__dimensions"
          v-html="dimensions ? dimensions : ''"
        ></span>
        <span class="file-item__name">{{ file.name }}</span>
      </div>
      <button class="trash" @click="deleteFile">
        <span class="icon is-small"> <svg-icon icon="trash" /> </span>
      </button>
    </div>
  </component>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    type: {
      type: String
    },
    tag: {
      default: "div"
    },
    url:{
      type: String,
    }
  },
  computed: {
    dimensions() {
      if (this.file.width && this.file.height) {
        return `${this.file.width} x ${this.file.height}`;
      }
      return false;
    },
    orderHashQuery() {
      return this.$route.query.hasOwnProperty("hash")
          ? `?hash=${this.$route.query.hash}`
          : ""
    },
  },
  methods: {
    async deleteFile() {
      if (
        confirm(
          "Als je dit bestand verwijdert kunnen wij deze niet meer inzien"
        )
      ) {
        if(this.url != null){
          await this.$http.delete(`${this.url}/${this.file.name}`);
        }else{
          await this.$http.delete(`${this.$route.path}/file/${this.file.name}${this.orderHashQuery}`);
        }
        this.$emit("sync");
      }
    }
  }
};
</script>
<style lang="scss">
@import "~vars";
.file-item {
  padding: 0.35em 0.5em !important;
  &.linked {
    background-color: $cyan;
    color: #fff;
    .trash{
      background-color: $cyan;
    }
  }

  &.unlinked {
    background-color: $grey-lightest;
    border-bottom: 1px solid $grey-lighter;
    .trash{
      background-color: $grey-lightest;
    }
  }

  &.suggestion {
    background-color: $background-yellow;
    .trash{
      background-color: $background-yellow;
    }
  }
}

.file-item__content {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.3rem;
  cursor: move;
  padding: 0.5rem;
}


.trash{
  cursor: pointer;
}

.file-item__dimensions {
  font-style: italic;
  width: 8rem;
  display: inline-block;
}

.file-item__name {
  margin-left: 1rem;
}
</style>
