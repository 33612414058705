<template>
  <div class="container" :class="{ 'is-fluid': el.is['is-large'] || el.is['is-small']}">
    <div class="loading" v-if="loading">
      {{ $t("ui.loading") }}
    </div>
    <div v-if="order && !loading" class="order" :class="el.is">
      <div class="order__sidebar" >
        <div class="subtitle" v-if="el.is['is-small']">
          <div class="title">{{order.id}}</div>
          <p class="subtitle c-cyan jc-sb">
            {{ order.details.reference }}
          </p>
          <p class="subtitle">
            {{ order.details.delivery_date | date }}
          </p>
          <div class="columns">
            <div class="column" v-if="!order.details.pickup && order.expedition">
              <order-label :value="order.expedition.via" bold />
              <order-label :value="order.expedition.tav" />
              <order-label :value="order.expedition.klantnaam" />
              <order-label :value="order.expedition.straat" />
              <order-label :value="order.expedition.postkode+' '+order.expedition.plaats" />
            </div>
            <div class="column" v-else-if="order.details.pickup">
              <order-label value="Order wordt zelf opgehaald" bold/>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <h2 class="subtitle">
              {{ order.details.delivery_date | date }}
            </h2>
          </div>
          <h2 class="subtitle c-cyan jc-sb">
            {{ order.details.reference }}
          </h2>
        </div>
        <div class="panel" v-if="order.details.description">
          <div class="panel-heading">
            <p> {{ $t("order.description") }}</p>
          </div>
          <div class="panel-block">
            <p class="description-field">
              {{ order.details.description }}
            </p>  
          </div>
        </div>
        <div class="button-column">
          <button
            class="button is-primary"
            @click.prevent="openImages()"
            v-if="order.state !== 'quote' && order.state !== 'template'&& order.state !== 'cancel'  && order.needs_approval === false && !order.copies[0].name.includes('PlusFrame')"
          >
            {{ $t("order.files") }}
          </button>
          <button
            class="button is-info"
            @click.prevent="images = true"
            v-if="order.needs_approval === true"
          >
            {{ $t("approval.title") }}
          </button>
          <button
            @click.prevent="preview"
            class="button is-primary"
            :class="{ 'is-loading': download }"
            v-if="order.state !== 'quote' && order.state !== 'template' && order.state !== 'cancel' && !order.intake"
          >
            {{ $t("order.preview_pdf") }}
          </button>
          <button
            class="button is-primary"
            @click="createOrder"
            v-if="order.state == 'template'"
          >
            {{ $t("order.template_order") }}
          </button>
          <button
            class="button is-primary"
            @click.prevent="edit"
            v-if="
              (order.edit || order.state == 'template') &&
                $store.getters.auth
            "
          >
            {{ $t("order.edit", { state: $t("order." + order.state) }) }}
          </button>

          <button
            class="button is-primary"
            v-if="!$store.getters.auth"
            @click="loginModalState = true"
          >
            {{ $t("order.edit", { state: $t("order." + order.state) }) }}
          </button>

          <button
            class="button is-primary"
            @click.prevent="approveQuote"
            v-if="order.state === 'quote'"
          >
            {{ $t("order.accept_quote") }}
          </button>
          <button
            class="button"
            @click="deleteOrder"
            v-if="order.state === 'quote'"
          >
            {{ $t("order.deactivate") }}
          </button>
          <button
            class="button"
            @click="dialog=true"
            v-if="order.state === 'template'"
          >
            {{ $t("order.delete") }}
          </button>
        </div>
        <div v-if="!el.is['is-small']" class="order__progress">
          <div class="panel" :class="[order.state === 'cancel' ? 'is-danger':'']">
            <p
              class="panel-heading"
              :class="{ 'bc-danger': order.waiting_files }"
              v-html="
                order.waiting_files
                  ? $t('progress.waiting')
                  : $t('progress.label')
              "
              v-if="order.state !== 'cancel'"
            ></p>
            <p class="panel-heading" v-else>Order {{$t('order.deleted')}}</p>
            <template v-if="!order.waiting_files">
              <div
                v-for="(process, index) in order.processes"
                class="panel-block"
                :class="{ 'is-active': process.status === 'Actief' }"
                :key="index + 1"
              >
                <span class="panel-icon icon">
                  <svg-icon :icon="processIcon(process.status)" />
                </span>
                {{ $t("progress." + process.procesnaam.toLowerCase()) }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <main class="order__main">
        <div class="columns is-multiline" v-if="!el.is['is-small']">
          <div class="column">
            <order-label :value="$t('order.partner')" bold />
            <order-label :value="order.partner.id.toString()" />
            <order-label :value="order.partner.name" />
            <order-label :value="order.partner.address.street" />
            <order-label
              :value="
                order.partner.address.zip + ' ' + order.partner.address.place
              "
            />
          </div>
          <div class="column">
            <order-label :value="$t('order.client')" bold />
            <order-label :value="order.contact.name" />
            <order-label :value="order.contact.phone" />
            <order-label :value="order.contact.email" email />
          </div>
          <div class="column" v-if="!order.details.pickup">
            <order-label :value="order.expedition.via" bold />
            <order-label :value="order.expedition.tav" />
            <order-label :value="order.expedition.klantnaam" />
            <order-label :value="order.expedition.straat" />
            <order-label :value="order.expedition.postkode+' '+order.expedition.plaats" />
          </div>
          <div class="column" v-else>
            <order-label value="Order wordt zelf opgehaald" bold/>
          </div>
          <div class="column">
            <order-label :value="$t('order.order_date')" bold />
            <order-label>{{
              order.details.order_date
                | date({ year: "numeric", month: "long", day: "numeric" })
            }}</order-label>
          </div>
        </div>
        <hr />
        <div v-if="!el.is['is-small']">
          <order-copies
            :copies="order.copies"
            :narrow="el.is['is-medium-only'] || el.is['is-small']"
          />
          <hr v-if="!order.isParent" />
          <order-finishes
            v-if="order.activities.length > 0"
            :finishes="order.activities"
          />
          <order-prices
            v-if="order.invoice_lines.length > 0 && !order.isParent"
            :invoice-lines="order.invoice_lines"
            :product="order.copies[0].name"
          />
        </div>
        <div class="column" v-else>
          <div class="title">{{order.copies[0].name}}</div>
          <div class="card mobile" v-for="copy in order.copies" :key="copy.id">
            <div class="card-content">
              <div class="content">
                <div class="title">
                </div>
                <div class="subtitle">
                  <div class="leftbox">
                    <p><b>{{ $t('copies_short.quantity') }}</b> : {{copy.qty}}</p>
                    <p><b>{{ $t('copies_short.dimensions') }} </b>: {{copy.width}}x{{ copy.height }}</p>
                    <div v-if="copy.remark != ''"><p><b>{{ $t('copies_short.remark') }}</b> : {{copy.remark}}</p></div>
                  </div>
                  <div class="rightbox">
                    <div>
                      <b>{{ $t('copies_short.file_remark') }}</b>
                      <template>
                        <p v-if="copy.file_front">
                          <strong>{{ $t('files.front') }}:</strong> {{ copy.file_front }}
                        </p>
                        <p v-if="copy.file_back">{{ $t('files.back') }}: {{ copy.file_back }}</p>
                        <p v-if="!copy.file_front || !copy.file_front">
                          {{ $t('files.empty') }}
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="columns is-desktop mobile-price">
            <div class="column activity-headers">
              <div class="columns is-mobile">
                <b class="column finish-product">{{ $t('order.finish' )}}</b>
                <b class="column finish-zijde">{{ $t('order.side' )}}</b>
              </div>
            </div>
            <div class="column">
              <div class="columns is-mobile" v-for="(finish) in order.activities" :key="finish.id">
                <div class="column finish-product">{{finish.product_name}}</div>
                <div class="column finish-zijde">{{finish.opmerking}}</div>
              </div>
            </div>
            <hr>
            <div class="column finish-product">
              <b>{{ $t('financial.omschrijving') }}</b>
            </div>
            <div class="column">
              <div class="columns is-mobile" v-for="(line, index) in order.invoice_lines" :key="index">
                <div class="column prijs-beschrijving">{{line.omschrijving}}</div>
                <div class="column prijs-opbouw">{{ line.aantal.toFixed(2)}}</div>
                <div class="column prijs-opbouw">{{line.bedrag| currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","})}}</div>
              </div>
              <div class="columns is-mobile">
                <div class="column prijs-beschrijving"><b>{{ $t('financial.total_ex') }}</b></div>
                <div class="column"></div>
                <div class="column prijs-opbouw">{{totalPrice| currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","})}}</div> 
              </div>
            </div>
            <hr>
          </div>
        </div>
      </main>
    </div>

    <!-- Dialoog Modal -->
    <modal 
      v-if="dialog"
      v-model="dialog" 
      ref="modal"
      :title="translatedText('ui.close_dialog')"
    >
      <div class="columns is-centered ">
        <div class="column is-offset-one-quarter">
          <button class="button is-primary is-normal" @click="deleteOrder">{{ $t("ui.yes") }}</button>
        </div>
        <div class="column is-6">
          <button class="button is-primary is-normal" @click="dialog=false">{{ $t("ui.no") }}</button>
        </div>
      </div>
    </modal>

    <!-- Bestanden Modal -->
    <modal
      v-if="images"
      v-model="images"
      :title=imageModalTitle
      class="images-modal"
      ref="modal"
      skipCardContent
    >
      <image-director
        v-if="order.intake || child && child.intake"
        :url=" child !== null ? `${childOrderUrl()}/file`:`${orderUrl}/file${orderHashQuery}`"
        :copies="copies"
        :orderId="parseInt(orderId)"
        :partner="partner"
        ref="director"
        @updateCopies="copiesChanged = true"
        @quickedit="quickedit($event)"
      />

      <image-viewer
        v-else
        :url=" child !== null ? `${childOrderUrl()}/file`:`${orderUrl}/file${orderHashQuery}`"
        :activities="order.activities"
        :copies="copies"
        :approvalNeeded="order.needs_approval"
        @close="images = false"
      />

      <template slot="footer">
        <button
          type="button"
          class="button is-primary bg-cyan is-fullwidth"
          @click.prevent="$refs.director.confirm()"
          v-if="order.intake" 
        >
          {{ $t("files.confirm") }}
        </button>
      </template>
    </modal>

    <!-- Edit the order -->
    <edit-order
      v-if="$store.state.user && order && editOrder"
      :order="editOrder"
      @close="editOrder = undefined"
      @sync="
        () => {
          this.editOrder = undefined;
          this.fetchData();
        }
      "
    />
    <modal v-if="editing" v-model="editing" tag="form" title="Pas gegevens aan">
      <order-form
        :products="products"
        v-model="form"
        :partner="
          $store.state.user.partners &&
            $store.state.user.partners.find(uPartner => uPartner === partner)
            ? null
            : partner
        "
      />
      <template slot="footer">
        <button
          type="button"
          class="button is-primary bg-cyan is-fullwidth"
          :class="{ 'is-loading': accept }"
          @click.prevent="acceptQuote"
        >
          Bevestig Offerte
        </button>
      </template>
    </modal>

    <modal 
      v-if="loginModalState"
      v-model="loginModalState" 
      ref="modal"
      :title="$t('ui.login_required')"
    >
      <div class="columns is-centered ">
        <router-link class="button is-primary is-normal" style="width: 6rem" :to="{name: 'login'}">{{ $t('router.login')}}</router-link>
      </div>
    </modal>
  </div>
</template>
<script>
import OrderLabel from "../components/Order/OrderLabel.vue";
import ImageDirector from "../components/Images/ImageDirector.vue";
import ImageViewer from "../components/Images/ImageViewer.vue";
import OrderCopies from "../components/Order/Views/OrderCopies";
import EditOrder from "../components/Order/EditOrder";
import Modal from "../components/Modal";
import OrderForm from "../components/Checkout/OrderForm";
import OrderPrices from "../components/Order/Views/OrderPrices";
import OrderFinishes from "../components/Order/Views/OrderFinishes";
import responsiveMixin from "../utilities/responsiveMixin";
import { SET_LOADING } from "../store/mutation-types";

// function is used twice should rewrite into helper class
const translateSide = (side) => {
  switch (side) {
    case "boven":
      return "top";
    case "onder":
      return "bottom";
    case "links":
      return "left";
    case "rechts":
      return "right";
  }
};
import currency from "../utilities/filters/currency";
export default {
  name: "Order",
  props: ["orderId"],
  filters: {
    currency
  },
  data() {
    return {
      loading: false,
      order: null,
      child: null,
      copies: null,
      error: null,
      editing: false,
      images: false,
      download: false,
      copiesChanged: false,
      accept: false,
      editOrder: false,
      dialog: false,
      imageModalTitle: "Bestanden",
      quickediting: false,
      loginModalState: false
    };
  },
  computed: {
    totalPrice() {
      return this.order.invoice_lines.reduce((accumulator, invoiceLine) => {
        return (accumulator += invoiceLine.bedrag);
      }, 0);
    },
    products() {
      if (!this.order) return [{}];
      return [
        {
          product_id: this.order.product_id,
          copies: this.order.copies.map(copy => {
            return {
              originals: copy.pages,
              quantity: copy.qty,
              width: copy.width,
              height: copy.height,
              sides: copy.sides,
              abaa: copy.abaa,
              id: copy.id,
              remark: copy.remark
            };
          }),
          finishes: this.order.activities.map(finish => {
            return {
              finishing_id: finish.product_id,
              side: finish.opmerking
            };
          })
        }
      ];
    },
    orderUrl() {
      return `/order/${this.orderId}`;
    },
    orderHashQuery() {
      console.log(this.$route.query.hash);
      return this.$route.query.hasOwnProperty("hash")
        ? `?hash=${this.$route.query.hash}`
        : "";
    },
    orderStateId() {
      return this.$t("order." + this.order.state) + " " + this.order.id;
    },
    form: {
      get() {
        if (!this.order) return {};
        // Convert delivery date to new date
        let details = this.order.details;
        if (!details.address) {
          details.address = false;
        }

        return {
          contact: this.order.contact,
          details: details
        };
      },
      set(object) {
        this.order[object.model] = object.value;
      }
    },
    childform: {
      get() {
        if (!this.child) return {};
        // Convert delivery date to new date
        let details = this.child.details;
        if (!details.address) {
          details.address = false;
        }

        return {
          contact: this.child.contact,
          details: details,
          
        };
      },
      set(object) {
        this.child[object.model] = object.value;
      }
    },
    partner() {
      if (this.order) {
        return this.order.partner.id;
      }

      return null;
    }
  },
  created() {
    this.fetchData().then(() => {
      const openImages = this.$route.query["action"] === "images";
      if (openImages) {
        this.images = openImages;
      }
    });
  },
  watch: {
    $route: "fetchData",
    images(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.fetchData();
      }
    }
  },
  mixins: [responsiveMixin],
  methods: {
    openImages() {
      this.imageModalTitle = "Bestanden";
      this.images = true;
    },
    quickedit(title){
      this.quickediting = !this.quickediting;
      this.imageModalTitle = title;
    },
    translatedText(text){
      return this.$t(text);
    },
    childOrderUrl(){
      return `/order/${this.child.id}`;
    },
    changechild(child){
      this.child = child;
      this.copies = Object.assign(child.copies, {});
    },
    edit(e,child = null) {
      if(child){
        this.child = child;
        let childProducts = [
        {
          product_id: child.product_id,
          copies: child.copies.map(copy => {
            return {
              originals: copy.pages,
              quantity: copy.qty,
              width: copy.width,
              height: copy.height,
              sides: copy.sides,
              abaa: copy.abaa,
              id: copy.id,
              remark: copy.remark
            };
          }),
          finishes: child.activities.map(finish => {
            return {
              finishing_id: finish.product_id,
              side: finish.opmerking
            };
          })
        }
      ];
      this.editOrder = { ...this.childform, ...childProducts[0] };
      }
      else{
        this.editOrder = { ...this.form, ...this.products[0] };
      }
      
    },
    async preview() {
      this.download = true;
      const response = await this.$http({
        url: `/preview/${this.orderId}${this.orderHashQuery}`,
        method: "GET",
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `preview_${this.orderId}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
      this.download = false;
    },
    processIcon(processStatus) {
      switch (processStatus) {
        case "Actief":
          return "play";
        case "Afgewerkt":
          return "checkmark";
        case "Gepauzeerd":
          return "pause";
        case "Ingepland":
        default:
          return "pending";
      }
    },
    async fetchData() {
      this.error = this.post = null;
      this.loading = true;
      try {
        const fetch = await this.$http.get(this.orderUrl + this.orderHashQuery);
        this.order = fetch.data;
        this.copies = Object.assign(this.order.copies, {});
        this.loading = false;
      } catch (error) {
        this.$router.push("/");
      }
    },
    approveQuote() {
      this.editing = true;
    },
    async createOrder(){
      const { data } = await this.$http.get(`/order/${this.order.id}`);
      this.$store.dispatch("createPrint", data.product_id);
      this.$store.dispatch(
          "addFinishes",
          data.activities
            .map((finish) => {
              return {
                name: finish.omschrijving,
                finishing_id: finish.product_id,
                side: translateSide(finish.opmerking),
              };
            })
            .filter(
              (finish) =>
                this.$store.getters.currentFinishes.findIndex(
                  (currentFinish) =>
                    currentFinish.finishing_id === finish.finishing_id
                ) !== -1
            )
        );
      this.$store.dispatch(
        "addCopiesCopyOrder",
        data.copies.map((copy) => {
          return {
            originals: copy.pages,
            quantity: copy.qty,
            width: copy.width,
            height: copy.height,
            sides: copy.sides,
            abaa: copy.abaa,
            id: copy.id,
            remark: copy.remark,
          };
        })
      );
      this.$router.push({ name: "checkout" });
    },
    async deleteOrder() {
      try {
        await this.$http.delete(this.orderUrl);
        this.$router.push({ name: "home" });
        this.$toasted.success("Order has been deleted");
      } catch (error) {
        this.$toasted.error("something went wrong");
      }
    },
    async acceptQuote() {
      this.accept = true;
      try {
        await this.$http.put(
          `/order/${this.orderId}/confirm/${
            this.$route.query.hasOwnProperty("hash")
              ? `?hash=${this.$route.query.hash}`
              : ""
          }`,
          this.form
        );

        this.fetchData();
        this.editing = false;
        this.accept = false;
      } catch (error) {
        this.$toasted.error("something went wrong");
        this.accept = false;
      }
    }
  },
  components: {
    OrderLabel,
    Modal,
    OrderForm,
    OrderCopies,
    OrderPrices,
    OrderFinishes,
    EditOrder,
    ImageDirector,
    ImageViewer
  }
};
</script>
<style lang="scss">
@import "~vars";

.order {
  display: flex;
  flex-direction: row;

  .order__main {
    flex-grow: 1;
  }

  .order__sidebar {
    width: 300px;
    min-width: 300px;
  }

  .order__progress {
    margin-top: 2rem;
  }

  &.is-small {
    flex-direction: column;
    .order__sidebar {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  &.is-medium {
    .order__sidebar {
      margin-right: 1.5rem;
    }
  }

  &.is-large,
  &.is-wide {
    .order__sidebar {
      margin-right: 3rem;
    }
  }
}

.order-info {
  display: flex;
  align-items: flex-end;

  .icon {
    margin-right: 0.5rem;
  }
}

.button-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .button {
    margin: 0.5rem 0;
    width: 100%;
  }
}

.images-modal .modal-content {
  width: 80%;
  @media screen and (max-width: $tablet) {
    width: 100%;
  } 
  }

.images-card {
  overflow: initial;
}

.menu-list {
  display: block;
  min-height: 50px;
}
@media print{
  .order.id{
    position: absolute;
    top: 15%;
    left: 10%;
    font-size: 30px;
    font-weight: bold;
    color: black;
  }
}
.description-field{
  padding: 1rem;
  font-style: italic;
}
</style>

<style lang="scss" scoped>
.card{
  margin-bottom: 0.5rem;
  .subtitle{
    .leftbox{
      min-width: fit-content !important;
      padding-right: 5%;

    }
    .rightbox{
      min-width: 0 !important;
      width: 40%;
      font-size: small;
    }
  }
}

.finish-product{
  font-size: small;
  min-width: 13rem;
}
.finish-zijde{
  font-size: small;
  text-align: right;
  max-width: 5rem;
  min-width: 5rem;
  margin-right: 2rem
}
.prijs-opbouw{
  font-size: small;
  text-align: right;
  max-width: 5rem;
  min-width: 5rem;
  margin-right: 1rem;
}
.prijs-beschrijving{
  font-size: small;
  min-width: 10rem;
}
.mobile-price{
  display: flex;
  flex-direction: column;
   
}
</style>