<template>
  <tr>
    <td>{{ copy.name }}</td>
    <td>{{ copy.width }}x{{ copy.height }}</td>
    <td>{{ copy.pages }}</td>
    <td>{{ copy.qty }}</td>
    <td>{{ copy.sides === 2 && copy.abaa === 1 ? 2 : 1 }}</td>
    <td style="word-break: break-word">
      <p v-if="copy.remark">{{ copy.remark }}</p>
      <template v-if="!narrow">
        <p v-if="copy.file_front">
          <strong>{{ $t('files.front') }}:</strong> {{ copy.file_front }}
        </p>
        <p v-if="copy.file_back">{{ $t('files.back') }}: {{ copy.file_back }}</p>
        <p v-if="!copy.file_front || !copy.file_front" class="empty-text">
          {{ $t('files.empty') }}
        </p>
      </template>
    </td>
    <td v-if="copy.price">
      {{ copy.price | currency("€", 2, {thousandsSeparator: ".", decimalSeparator: ","}) }}
    </td>
  </tr>
</template>
<script>
import currency from "../../../utilities/filters/currency";
export default {
  props: {
    copy: {
      type: Object,
      required: true
    },
    narrow: Boolean
  },
  filters: {
    currency
  }
};
</script>

<style lang="scss" scoped>
@import "~vars";
.empty-text {
  color: $magenta;
}
</style>
