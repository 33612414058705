<template>
  <div class="extra-wrapper">
    <button class="button is-info" @click="showExtra = !showExtra" v-html="showExtra ? $t('files.hide_extra') : $t('files.show_extra')" />
    <div class="extra-files" v-show="showExtra">
      <figure class="extra-files__image" v-for="image in extra" :key="image.url">
        <figcaption class="copy__title">{{ image.name || '' }}</figcaption>
        <image-file
          :image="image"
          :url="url"
          :alt="image.name"
        />
      </figure>
    </div>
  </div>
</template>
<script>
import ImageFile from "./ImageFile";

export default {
  props: {
    extra: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showExtra: false,
    }
  },
  components: {
    ImageFile
  }
}
</script>
<style lang="scss">
.extra-wrapper {
  flex: 0 0 100%;

  .extra-files {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    margin-top: 1rem;

    .extra__title {
      flex: 0 0 100%;
    }

    .extra-files__image {
      flex: 0 0 30%;
      margin-right: 2rem;
      @media screen and (max-width: 768px) {
        flex: 0 0 100%;
      }
    }
  }
}
</style>