<template>
  <div class="image-director">
    <div class="quick-edit" v-if="editLine">
      <quick-edit :base-url="baseUrl" :productlineid="selectedProductLine" @close="finishEditing" ></quick-edit>
    </div>
    <div class="image-director" v-else>
      <div class="image-director__head">
        <h2 class="subtitle">1. {{ $t('files.upload') }}</h2>
        <drag-n-drop :url="url" @updateFiles="sync" />
        <hr />
        <h2 class="subtitle attached-legenda">
          <p>2. {{ $t('files.attach') }}</p> 
          <span class="legenda-item legenda-item__suggested">= {{ $t('files.suggested') }}</span>
          <span class="legenda-item legenda-item__attached">= {{ $t('files.attached') }}</span>
        </h2>
      </div>
      <div class="image-director__body" v-if="!loading">
        <div class="copies__files">
          <order-copies :copies="copies" :narrow="true">
            <template v-slot="{ copies }">
              <drag-copy
                v-for="(copy, key) in copies"
                :key="copy.id"
                v-model="copies[key]"
                :files="getFilesForRow(copy.id)"
                :url="url"
                @update="update"
                @sync="sync"
                @remove="remove"
                @quickedit="quickedit($event)"
              />
              <div class="blanc" v-if="partner == 103426">
                <button id="blancBtn" class="button is-primary"  @click="createBlancAllLines" v-if="!false">{{$t('files.download_order')}}</button>
              </div>
            </template>
          </order-copies>
        </div>
        <div class="todo__files">
          <strong class="todo__label">{{ $t('files.todo') }}</strong>
          <draggable v-model="todo" group="people" @change="mapTodo" @remove="remove">
            <file-item
              v-for="item in todo"
              :file="item"
              :url="url"
              :key="item.file"
              type="unlinked"
              @sync="sync"
            />
          </draggable>
        </div>
      </div>
      <span v-else class="button is-borderless is-loading"></span>
    </div>
  </div>
</template>
<script>
import DragNDrop from "./DragNDrop";
import OrderCopies from "../Order/Views/OrderCopies";
import DragCopy from "./DragCopy";
import FileItem from "./FileItem";
import draggable from "vuedraggable";
import '../QuickEdit.js';

export default {
  props: {
    url: String,
    copies: Array,
    orderId: Number,
    partner: Number,
  },
  data() {
    return {
      loading: true,
      linked: [],
      todo: [],
      suggestion: [],
      editLine: false,
      selectedProductLine: null,
      baseUrl: process.env.VUE_APP_BASE_URI,
    };
  },
  methods: {
    async createBlancAllLines(){
      await this.$http.post(`createBlancAllLines/${this.orderId}`)
      .then(result =>{
        this.downloadFiles();
      })
      .catch(error => {
        console.log(error);
      })
    },
    finishEditing(){
      this.editLine = !this.editLine;
      // change title back to bestanden
      this.$emit('quickedit', 'Bestanden');
    },
    quickedit(id){
      this.editLine = !this.editLine;
      this.selectedProductLine = id;
      this.$emit('quickedit', 'Quick edit : '+id);
      
    },
    downloadFiles(){
      this.$http.get(`/downloadfiles/${this.orderId}`,{
        responseType: 'blob'
      })
      .then(response =>{
          console.log(response.data);
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.orderId + '_blanco.zip');
          document.body.appendChild(link);
          link.click();
      })
    },
    getFilesForRow(rowId) {
      return [
        ...this.linked
          .filter(file => file.lineId === rowId)
          .map(file => {
            file.type = "linked";
            return file;
          }),
        ...this.suggestion
          .filter(file => file.lineId === rowId)
          .map(file => {
            file.type = "suggestion";
            return file;
          })
      ];
    },
    mapTodo(event) {
      // Only map the array added to the draggable group
      const type = Object.keys(event)[0];
      if (type === "added") {
        this.todo = this.todo.map(this.mapFile);
      }
    },
    mapFile: file => ({
      name: file.name,
      height: file.height,
      width: file.width,
      type: "unlinked",
      oldType: file.oldType || file.type,
      oldLineId: file.oldLineId || file.lineId
    }),
    remove(file) {
      if (file.lineId) {
        // get type
        // try to unlink linked or suggestion
        this.linked = this.linked.filter(link => link.name !== file.name);
        this.suggestion = this.suggestion.filter(
          suggestion => suggestion.name !== file.name
        );
        // if file is not already in todo list push to
        if (!this.todo.find(todo => todo.name === file.name)) {
          this.todo.push(file);
          this.todo.map(this.mapFile);
        }
      }
    },
    update(items) {
      // Assume the update is always for the suggestion map
      // Filter the suggestion by removing the items and then readding them
      this.suggestion = this.suggestion.filter(file => {
        return !items.find(
          item => item.name === file.name && item.lineId === file.lineId
        );
      });
      this.suggestion = [...this.suggestion, ...items];
    },
    async confirm() {
      this.loading = true;
      let promises = [];

      const hash = this.$route.query.hasOwnProperty("hash")
          ? `?hash=${this.$route.query.hash}`
          : ""

      this.todo
        .filter(file => file.oldType === "linked" && file.oldLineId)
        .forEach(file => {
          promises.push(
            this.$http.delete(this.$route.path + "/file/unlink/" + file.oldLineId)
          );
        });

      this.suggestion.forEach(file => {
        promises.push(
          this.$http.put(this.url, {
            file: file.name,
            lineId: file.lineId,
            position: file.position
          })
        );
      });

      await Promise.all(promises);
      await this.sync();

      this.loading = false;
    },
    async sync() {
      // get current status from server and reset the data properties
      const { data } = await this.$http.get(this.url);
      for (let key in data) {
        this[key] = data[key];
      }
      this.todo = this.todo.filter(file =>
          !this.suggestion.find(suggestion => suggestion.name === file.name)
      );
      this.updateCopies();
    },
    updateCopies() {
      const copies = this.copies;
      copies.map(copy => {
        copy.file_front = "";
        copy.file_back = "";
        return copy;
      });

      this.linked.forEach(link => {
        const copy = copies.find(copy => copy.id === link.lineId);
        copy[`file_${link.position}`] = link.name;
      });
      this.$emit("updateCopies", copies);
    }
  },
  async created() {
    this.loading = true;
    await this.sync();
    this.loading = false;
  },
  components: {
    DragNDrop,
    OrderCopies,
    DragCopy,
    FileItem,
    draggable
  }
};
</script>
<style lang="scss">
@import "~vars";

.quick-edit{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(229 231 235 / 1);
  align-items: center;
}
.image-director {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-height: 100%;
  overflow-y: scroll;

  &__body {
    display: flex;
    max-height: 100%;
    overflow-y: hidden;
    flex: 1 0 auto;
    //  mobile and tablet
    @media (max-width: 1675px) {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .subtitle {
      margin-bottom: 0;
    }
  }
  .blanc{
    padding-top: 2rem;
  }

  .todo__files,
  .copies__files {
    overflow-y: scroll;
    max-height: 100%;
    flex: 1 0 auto;
    padding: 1rem;
    @media (max-width: 1675px) {
      max-height: 12rem;
      max-width: 100%;
    }
  }
 
  .copies__files {
    max-width: 60%;
    //  mobile and tablet
    @media (max-width: 1675px) {
      max-width: 100%;
    }
  }

  .todo__label {
    padding: 0.3rem;
    display: block;
    border-bottom: 2px solid $grey-lighter;
    line-height: 1.2;
  }
}

.attached-legenda {
  p{
    padding-bottom: 0.5rem;
  }
  align-items: center;

  .legenda-item {
    display: inline-flex;
    align-items: center;
    &::before {
      content: "";
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-left: 0.5rem;
      margin-right: 0.25rem;
    }

    &__attached::before {
      background-color: $cyan;
    }
    &__suggested::before {
      background-color: $background-yellow;
    }
  }
}
</style>
