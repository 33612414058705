<template>
  <table class="invoice-lines table is-narrow is-fullwidth is-striped">
    <thead>
      <tr>
        <th>{{ $t('order.finish' )}}</th>
        <th>{{ $t('order.side' )}}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="finish in finishes"
        :key="finish.id"
      >
        <td>{{ finish.omschrijving }}</td>
        <td>{{ finish.opmerking }}</td>

      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    finishes: Array
  },
};
</script>
