<template>
  <strong class="order-label" v-if="value && bold">{{ value }}</strong>
  <a class="order-label" :href="'mailto:' + value" v-else-if="value && email">
    {{ value }}
  </a>
  <span class="order-label" v-else-if="value" v-html="value"></span>
  <span v-else><slot /></span>
</template>
<script>
export default {
  props: {
    value: String,
    bold: Boolean,
    email: Boolean
  }
};
</script>
<style>
.order-label {
  display: block;
  white-space: nowrap;
}
</style>
