<template>
  <div class="img-container">
    <a :href="lazySrc" target="_blank">
      <img
        ref="img"
        :src="thumbnailSrc"
        :data-src="lazySrc"
        :alt="alt"
        :style="style"
        class="file_image"
      />
    </a>
    <div class="loader" v-if="loading"></div>
  </div>
</template>
<script>
import lozad from "lozad";

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 320,
    },
    width: {
      type: Number,
      default: 320,
    },
    image: {
      type: Object,
      required: true
    },
    alt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    lazySrc() {
      const urlSplit = this.url.split(/(.+?(?=\?))/);
      return (
        this.$http.defaults.baseURL + urlSplit[1].replace("/", "") + '/' + this.image.url + urlSplit[2]
      );
    },
    thumbnailSrc() {
      const urlSplit = this.url.split(/(.+?(?=\?))/);
      return (
        this.$http.defaults.baseURL + urlSplit[1].replace("/", "").replace("/file", "/thumbnail/") + this.image.url + urlSplit[2]
      );
    },
    aspectRatio() {
      // Calculate the aspect ratio of the image
      // if the width and the height are given.
      if (!this.width || !this.height) return null;

      return (this.height / this.width) * 100;
    },
    style() {
      const style = {};

      if (this.width) style.width = `${this.width}px`;
      return style;
    }
  },
  mounted() {
    // As soon as the <img> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    const setLoadingState = () => {
      this.loading = false;
    };

    const $img = this.$refs.img;
    $img.addEventListener('load', setLoadingState);
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      $img.removeEventListener('load', setLoadingState);
    });

    // We initialize Lozad.js on the root
    // element of our component.
    const observer = lozad($img);
    observer.observe();
  },
};
</script>
<style lang="scss">
@import "~vars";

.img-container {
  position: relative;

  .loader {
    position: absolute;
    right: -1.75rem;
    bottom: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-left-color: $magenta;
    border-bottom-color: $magenta;
  }
}

.file_image {
  max-width: 100%;
  max-height: 100%;
  width: webkit-fit-content !important;
  height: webkit-fit-content !important;
  vertical-align: middle;
  cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in; 
  cursor: zoom-in;
  
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
</style>