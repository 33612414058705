<template>
  <div id="dropzone" ref="dropzoneElement" class="vue-dropzone dropzone">
  </div>
</template>
<script>
import Dropzone from 'dropzone';

Dropzone.autodiscover = false;

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  mounted() {
    let clear = true;

    const dropzone = new Dropzone(this.$refs.dropzoneElement, {
      url: this.$http.defaults.baseURL.slice(0, -1) + this.url,
      parallelUploads: 5,
      maxFilesize: 1500,
      timeout: 18000000,
      createImageThumbnails: true,
      addRemoveLinks: true,
      thumbnailWidth: 60,
      thumbnailHeight: 60,
      headers: this.$http.defaults.headers.common,
      acceptedFiles: "image/jpeg,image/bmp,image/svg+xml,image/png,image/gif,application/pdf",
      dictDefaultMessage: this.$t('dropzone.dictDefaultMessage'),
      dictFallbackMessage: this.$t('dropzone.dictFallbackMessage'),
      dictFallbackText: this.$t('dropzone.dictFallbackText'),
      dictFileTooBig: this.$t('dropzone.dictFileTooBig'),
      dictInvalidFileType: this.$t('dropzone.dictInvalidFileType'),
      dictResponseError: this.$t('dropzone.dictResponseError'),
      dictCancelUpload: this.$t('dropzone.dictCancelUpload'),
      dictUploadCanceled: this.$t('dropzone.dictUploadCanceled'),
      dictCancelUploadConfirmation: this.$t('dropzone.dictCancelUploadConfirmation'),
      dictRemoveFile: this.$t('dropzone.dictRemoveFile'),
      dictRemoveFileConfirmation: this.$t('dropzone.dictRemoveFileConfirmation'),
      dictMaxFilesExceeded: this.$t('dropzone.dictMaxFilesExceeded')
    });

    dropzone.on('complete', (file) => {
      const count = dropzone.getQueuedFiles().length + dropzone.getUploadingFiles().length;
      if (count === 0 && clear) {
        // dropzone.removeAllFiles();
      }

      this.$emit('updateFiles', []);
    })

    dropzone.on('error', (error, message, type) => {
      // Error message
      if (typeof message === 'object') {
        const errorMessageNode = [...error.previewElement.children].find(childNode => {
          return childNode.classList.contains('dz-error-message');
        })
        errorMessageNode.innerText = message.file;
      }

      clear = false;
    })
  }
}
</script>
<style lang="scss" scoped>
  @import '~dropzone/dist/dropzone.css';

  .vue-dropzone {
  border: 2px solid #e5e5e5;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: 0.2s linear;
  max-height: 150px;
  overflow-y: scroll;
}
.vue-dropzone:hover {
  background-color: #f6f6f6;
}
.vue-dropzone > i {
  color: #ccc;
}
.vue-dropzone > .dz-preview .dz-image {
  border-radius: 0;
  width: 100%;
  height: 100%;
}
.vue-dropzone > .dz-preview .dz-image img:not([src]) {
  width: 200px;
  height: 200px;
}
.vue-dropzone > .dz-preview .dz-image:hover img {
  transform: none;
  -webkit-filter: none;
}
.vue-dropzone > .dz-preview .dz-details {
  bottom: 0;
  top: 0;
  color: white;
  background-color: rgba(33, 150, 243, 0.8);
  transition: opacity 0.2s linear;
  text-align: left;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename {
  overflow: hidden;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename span,
.vue-dropzone > .dz-preview .dz-details .dz-size span {
  background-color: transparent;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: none;
}
.vue-dropzone > .dz-preview .dz-details .dz-filename:hover span {
  background-color: transparent;
  border: none;
}
.vue-dropzone > .dz-preview .dz-progress .dz-upload {
  background: #cccccc;
}
.vue-dropzone > .dz-preview .dz-remove {
  position: absolute;
  z-index: 30;
  color: white;
  margin-left: 15px;
  padding: 10px;
  top: inherit;
  bottom: 15px;
  border: 2px white solid;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 800;
  letter-spacing: 1.1px;
  opacity: 0;
}
.vue-dropzone > .dz-preview:hover .dz-remove {
  opacity: 1;
}
.vue-dropzone > .dz-preview .dz-success-mark,
.vue-dropzone > .dz-preview .dz-error-mark {
  margin-left: auto;
  margin-top: auto;
  width: 100%;
  top: 35%;
  left: 0;
}
.vue-dropzone > .dz-preview .dz-success-mark svg,
.vue-dropzone > .dz-preview .dz-error-mark svg {
  margin-left: auto;
  margin-right: auto;
}
.vue-dropzone > .dz-preview .dz-error-message {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  width: 100%;
  text-align: center;
}
.vue-dropzone > .dz-preview .dz-error-message:after {
  display: none;
}
</style>