<template>
  <div>
    <h1>Afwerkingen</h1>
    <ul class="activities__view">
      <li v-for="(activity, key) in groupedActivities" :key="key">
        {{ activity.name }}
        <span class="icon" v-for="side in activity.sides" :key="key + side">
          <svg-icon icon="finish-side" :styling="{ transform: `rotate(${deg(side)})` }"/>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    activities: {
      type: Array,
      required: true,
    }
  },
  methods: {
    deg(side) {
      switch (side) {
        case "right":
        case "rechts":
          return "90deg";
        case "bottom":
        case "onder":
          return "180deg";
        case "left":
        case "links":
          return "270deg";
        default:
          return "0deg";
      }
    }
  },
  computed: {
    groupedActivities() {
      return this.activities.reduce((group, activity) => {
        const collected = {
          name: activity.product_name,
          sides: []
        };
        (group[activity.product_id] = group[activity.product_id] || collected).sides.push(activity.opmerking)
        return group;
      }, {})
    }
  }
}
</script>
<style lang="scss">
  .activities__view .icon {
    margin-right: 0.5rem;
  }
</style>