<template>
  <div class="approval_form">
    <hr>
    <h2 class="subtitle">{{ $t('approval.title') }}</h2>
    <div class="control">
      <input class="input" type="text" v-model="name" :placeholder="$t('approval.name')">
    </div>
    <hr>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-info" v-if="reasons.length === 0" :class="{'is-loading': loading}" @click="approve" :disabled="loading">
          {{ $t('approval.approve_all') }}
        </button>
      </div>
      <div class="control">
        <button class="button is-danger" :class="{'is-loading': loading}" @click="disapprove" :disabled="loading" v-if="reasons.length > 0">
          <template>
            {{ $t('approval.disapprove') }}
          </template>
        </button>
        <button class="button is-danger" :class="{'is-loading': loading}" @click="dissaproveAll" :disabled="loading" v-else>
          <template>
            {{ $t('approval.disapprove_all') }}
          </template>
        </button>
      </div>
      <div class="control"  v-if="reasons.length == 0">
        <input class="input" type="text" v-model="reasonAll" :placeholder="$t('approval.dissaprove_text')">
      </div>
      <pre class="approval-message" v-if="reasons.length > 0">{{ format }}</pre>
    </div>
  </div>
</template>
<script>
import Vevent from "@/utilities/Vevent";
export default {
  data() {
    return {
      reasons: [],
      name: '',
      loading: false,
      reasonAll: '',
    }
  },
  computed: {
    url() {
      return `${this.$route.params.orderId}${this.$route.query.hash ? `?hash=${this.$route.query.hash}` : ''}`;
    },
    format() {
      return 'Afkeuringen: \n'
      + this.reasons.map(reason => {
        return `${reason.name} (${reason.file}): ${reason.reason}`;
      }).join('\n')
      + `\n\n${this.name}`;

      return message;
    }
  },
  methods: {
    approve() {
      this.loading = true;
      this.$http.post(`approve/${this.url}`, {
        reason: this.name
      }).then(result => {
        this.$toasted.success(this.$t('approval.success'))
        this.$emit('close');
      }).catch(error => {
        console.log(error);
      })
    },
    dissaproveAll(){
      if(this.reasonAll == '' && this.name == ''){
        this.$toasted.error('Geef naam of initiaal onder getekende en reden voor afkeuring ');
      }
      else if(this.reasonAll == ''){
        this.$toasted.error('Geef een reden voor afkeuring');
      }
      else if(this.name == ''){
        this.$toasted.error('Geef naam of initiaal onder getekende');
      }
      else
      {
        this.loading = true;
        this.$http.post(`disapprove/${this.url}`, {
          reason: `Alles afgekeurd reden: ${this.reasonAll} - ${this.name}`
        }).then(result => {
          this.$toasted.danger(this.$t('approval.failed'))
          this.$emit('close');
        }).catch(error => {
          console.log(error);
        })
      }
    },
    disapprove() {
      if(this.name !== ''){
      let formatted = this.format;
      this.$http.post(`disapprove/${this.url}`, {
        reason: formatted
      }).then(result => {
        this.$toasted.danger(this.$t('approval.failed'))
        this.$emit('close');
      }).catch(error => {
        console.log(error);
      })
      }else{
        this.$toasted.error('Geef naam of initiaal onder getekende');
      }
    }
  },
  mounted() {
    Vevent.listen("approval::disapprove", reason => {
      this.reasons.push(reason);
    });

    Vevent.listen("approval::approve", approved => {
      const index = this.reasons.findIndex(reason => reason.file === approved.file);
      this.reasons.splice(index, 1);
    })
  },
}
</script>
<style lang="scss">
.approval-message {
  margin-left: 1rem;
}
</style>