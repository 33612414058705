<template>
  <div class="image-viewer__side" :class="{'is-disapproved': disapproved}">
    <figure class="copy__image" :data-reason="reason">
      <figcaption class="copy__title" :title="image.name || ''">{{ image.name || '' }}</figcaption>
      <image-file
        :image="image"
        :url="url"
        :alt="image.name"
        :width="rotated ? image.height : image.width"
        :height="rotated ? image.width : image.height" />
    </figure>
    <template v-if="approvalNeeded">
      <div class="field field__approval">
        <div class="control">
          <input type="text" class="input" v-model="reason" :placeholder="$t('approval.reason')" :readonly="disapproved" :disabled="disapproved">
        </div>
      </div>
      <button class="button is-danger is-fullwidth" v-if="disapproved == false" @click="disapprove(image.url, image.name,reason)">{{ $t('approval.disapprove') }}</button>
      <button class="button is-success is-fullwidth" v-if="disapproved == true" @click="approve(image.url)">{{ $t('approval.approve') }}</button>
    </template>
  </div>
</template>
<script>
import Vevent from "@/utilities/Vevent";
import ImageFile from "./ImageFile";
export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true
    },
    rotated: {
      type: Boolean,
      default: false,
    },
    approvalNeeded: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      reason: '',
      disapproved: false,
    }
  },
  methods: {
    disapprove(file, name) {
      if(this.reason == ''){
        this.$toasted.error('Geef een reden voor afkeuring');
      }
      else{
      this.disapproved = true;
      Vevent.fire("approval::disapprove", {
        file,
        reason: this.reason,
        name
      })
      }
    },
    approve(file) {
      this.disapproved = false;
      this.reason = '';

      Vevent.fire("approval::approve", {
        file,
      })
    }
  },
  components: {
    ImageFile
  }
}
</script>
