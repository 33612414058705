<template>
  <modal
    contentClass="modal__edit-order"
    v-model="modal"
    :title="$t('ui.edit', {subject: order.details.reference})"
  >
    <div class="columns" v-if="editableOrder">
      <div class="column is-8">
        <copy-editor v-model="editableOrder.copies" :product="product" :disableOriginalsWithId="true"/>
      </div>
      <div class="column">
        <order-form
          :edit="true"
          :products="[{
            product_id: editableOrder.product_id,
            copies: editableOrder.copies,
            finishes: editableOrder.finishes
          }]" v-model="form" />
      </div>
    </div>
    <template slot="footer">
      <button
        type="button"
        class="card-footer-item bg-cyan c-white"
        @click.prevent="saveOrder"
      >
        Sla order op
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "../Modal";
import CopyEditor from "../Copies/CopiesEditor";
import OrderForm from "../Checkout/OrderForm";
import { validate } from "../../utilities/validators";
import copiesSchema from "../../schemas/copies.schema.json";
import clone from "lodash-es/cloneDeep";
import { SET_LOADING } from "../../store/mutation-types";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editableOrder: clone(this.order)
    }
  },
  computed: {
    product() {
      return this.$store.getters.getProduct(this.editableOrder.product_id);
    },
    form: {
      get() {
        if (!this.editableOrder) return {};
        // Convert delivery date to new date
        let details = this.editableOrder.details;
        if (!details.address) {
          details.address = false;
        }

        return {
          contact: this.editableOrder.contact,
          details: details
        };
      },
      set(object) {
        this.editableOrder[object.model] = object.value;
      }
    },
    modal: {
      get() {
        return Object.entries(this.order).length !== 0;
      },
      set(val) {
        this.$emit('close');
      }
    }
  },
  created() {
    this.editableOrder = clone(this.order);
    this.form.details = this.editableOrder.details;
    this.form.contact = this.editableOrder.contact;
  },
  methods: {
    saveOrder() {
      try {
        validate(copiesSchema, this.editableOrder.copies);
        // Make ajax call to save order
        this.$store.commit(SET_LOADING, true);
        this.editableOrder['lineId'] = this.editableOrder.copies[0].id;
        this.$http.patch(this.$route.path, this.editableOrder)
          .then(() => {
            this.$store.commit(SET_LOADING, false);
            this.$emit("sync");
          })
          .catch(console.log);
      } catch (errors) {
        errors.forEach(error => {
          this.$toasted.error(error.message + " " + error.params.allowedValue);
        });
      }
    }
  },
  components: {
    Modal,
    CopyEditor,
    OrderForm
  }
}
</script>
